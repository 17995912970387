import React from "react";
import { useMutation } from "@apollo/client";

import { Typography } from "@mui/material";

import MoovsDialog from "../../globals/MoovsDialog";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import { REQUEST_CANCELLATION_TO_TRIP } from "globals/graphql";
import { useAnalytics, useSnackbar } from "globals/hooks";
import { Trip } from "types";

type RequestCancellationToTripDialogProps = {
  open: boolean;
  onClose: () => void;
  trip: Trip;
};

function RequestCancellationToTripDialog(
  props: RequestCancellationToTripDialogProps
) {
  const { open, onClose, trip } = props;

  // hooks
  const snackbar = useSnackbar();
  const { track } = useAnalytics();

  // mutations
  const [requestTripCancellation, { loading }] = useMutation(
    REQUEST_CANCELLATION_TO_TRIP,
    {
      onCompleted() {
        track("cancellation_requested");
        onClose();
      },
      onError(error) {
        const errorMessage = getErrorMessage(error);
        snackbar.error(errorMessage);
      },
    }
  );

  // handlers
  const handleRequestTripCancellation = () => {
    requestTripCancellation({
      variables: {
        input: {
          tripId: trip.id,
        },
      },
    });
  };

  return (
    <MoovsDialog
      hideLoadingIndicator={false}
      acceptDisabled={loading}
      open={open}
      onClose={onClose}
      dialogTitle="Cancel Trip"
      size="xs"
      onAccept={handleRequestTripCancellation}
      acceptButtonText="Yes, request cancellation"
      acceptButtonColor="error"
      closeButtonText="No, keep"
    >
      <Typography variant="body2" p={2}>
        Are you sure you want to cancel trip? A cancellation request will be
        sent to the operator.
      </Typography>
    </MoovsDialog>
  );
}

export default RequestCancellationToTripDialog;
